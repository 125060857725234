import { useList } from '@zupr/hooks/request-redux'
import { t } from '@zupr/i18n'
import { Item } from '@zupr/types/generic'
import { useCallback, useMemo, useState } from 'react'

import { usePathname, useSearchParams } from '../../context/route'
import Searchbox from '../components/searchbox'
import FilterCollapse from './collapse'
import RemoveFilter from './remove'
import SelectItem from './select-item'
import { FilterAutocompleteConfiguration } from './types'

interface ItemWithTitle extends Item {
    title: string
    count?: number
}

interface RemoveProps {
    url: string
    filterKey: string
}

export const RemoveAutocompleteFilter = ({ url, filterKey }: RemoveProps) => {
    const searchParams = useSearchParams()
    const value = searchParams.get(filterKey)

    const [selectedResult] = useList<ItemWithTitle>({
        url,
        variables: {
            id__in: value,
        },
        pause: !value,
    })

    if (!(selectedResult && selectedResult.count)) return null

    return (
        <>
            {selectedResult.results.map((result) => (
                <RemoveFilter
                    key={result.id}
                    filterKey={filterKey}
                    value={value}
                    removeFromValue={result.id}
                >
                    {result.title}
                </RemoveFilter>
            ))}
        </>
    )
}

interface Props extends Omit<FilterAutocompleteConfiguration, 'type'> {
    type?: 'autocomplete'
}

const AutocompleteFilter = ({
    url,
    label,
    variables,
    filterKey,
    options,
    startCollapsed,
    initialLimit = 5,
    maxSelected,
}: Props) => {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const value = searchParams.get(filterKey)

    const [search, setSearch] = useState<string>()

    const selected = useMemo(() => {
        if (!value) {
            return []
        }
        return value.split(',')
    }, [value])

    const [active] = useList<ItemWithTitle>({
        url,
        pause: !value,
        variables: {
            id__in: value,
        },
    })

    const [optionsList] = useList<ItemWithTitle>({
        url,
        pause: !options?.length, // when options are provided, we use it as the initial list
        variables: {
            limit: options?.length,
            id__in: options?.map(({ id }) => id).join(','),
        },
    })

    const [list] = useList<ItemWithTitle>({
        url,
        pause: options?.length > 0 && !search, // when options are provided, we don't need to fetch the initial list
        variables: {
            ...variables,
            search,
            limit: !search ? initialLimit : 24,
        },
    })

    const inactive = useMemo(() => {
        if (list?.results)
            return list.results.filter((item) => !selected.includes(item.id))
        if (optionsList?.results)
            return optionsList.results
                .filter((item) => !selected.includes(item.id))
                .map((item) => ({
                    ...item,
                    count: options.find((o) => o.id === item.id)?.count,
                }))
        return []
    }, [list?.results, options, optionsList?.results, selected])

    const removeFromHref = useCallback(
        (item) => {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.set(
                filterKey,
                [...selected.filter((s) => s !== item.id)].join(',')
            )

            return `${pathname}?${newSearchParams.toString()}`
        },
        [filterKey, pathname, searchParams, selected]
    )

    const addToHref = useCallback(
        (item) => {
            const newSearchParams = new URLSearchParams(searchParams)
            const newSelected = [...selected, item.id]
            if (maxSelected && newSelected.length > maxSelected) {
                newSelected.shift()
            }
            newSearchParams.set(filterKey, newSelected.join(','))
            return `${pathname}?${newSearchParams.toString()}`
        },
        [filterKey, maxSelected, pathname, searchParams, selected]
    )

    return (
        <FilterCollapse header={label} startCollapsed={startCollapsed}>
            {(search || list?.count > 5 || options?.length > 5) && (
                <div>
                    <Searchbox
                        value={search}
                        placeholder={t('Zoeken naar %{label}', {
                            label: t(label).toLowerCase(),
                        })}
                        onChange={setSearch}
                        scanBarcode={false}
                    />
                </div>
            )}
            <div className="multiselect-filter">
                <ul>
                    {active?.results?.map((item) => (
                        <SelectItem
                            key={`${item.id}.checked`}
                            name={item.id}
                            title={item.title}
                            checked
                            href={removeFromHref(item)}
                        />
                    ))}
                    {inactive.map((item) => (
                        <SelectItem
                            key={`${item.id}.checked`}
                            name={item.id}
                            search={search}
                            title={item.title}
                            checked={false}
                            href={addToHref(item)}
                        />
                    ))}
                </ul>
            </div>
        </FilterCollapse>
    )
}

export default AutocompleteFilter
